import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorBoundary from 'exogen/components/error/error';
import TextField from 'shared/components/form/fields/text';
import * as Yup from 'yup';

const numberReg = /^\d+$/;
const numberRegMessage = 'Can only contain integers';

const requiredMessage = 'Required';

const validationSchema = Yup.object().shape({
  customer_number: Yup.string()
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  invoice_number: Yup.string()
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage)
});

const registerNewvalidationSchema = Yup.object().shape({
  customer_number: Yup.string()
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage)
});

class CorevistVerifyModal extends Component {
  state = {
    // showQuestion: false,      // don't show the question asking if you have an account or not
    // existingAccount: true    // set existing account to true, will not send new account information to corevist
    showQuestion: true,      // don't show the question asking if you have an account or not
    existingAccount: null    // set existing account to true, will not send new account information to corevist
  };

  handleQuestion = existingAccount => {
    this.setState({
      showQuestion: false,
      existingAccount
    });
  };

  reopenNew = () => {
    this.props.clearError();
    this.setState({ existingAccount: false });
  };

  render() {
    const { decline, error, invalidInvoiceCustomer, loading, show } = this.props;

    return (
      <Modal show={show} onHide={decline}>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ErrorBoundary>
            {this.state.showQuestion && (
              <>
                <Row className="form-group">
                  <Col>
                    <h5>Existing or New Account</h5>
                    <p>Do you have a customer & invoice number available?</p>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={12}>
                    <div className="pull-right">
                      <Button
                        className="btn btn-primary btn--fw"
                        onClick={() => this.handleQuestion(true)}
                      >
                        <span>
                          <i className="fa fa-check fa-fw" />
                          Yes
                        </span>
                      </Button>{' '}
                      <Button
                        className="btn btn-default btn--fw"
                        onClick={() => this.handleQuestion(false)}
                      >
                        <span>
                          <i className="fa fa-times fa-fw" />
                          No
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {!this.state.showQuestion && !this.state.existingAccount && (


                <Formik
                  displayName={'RegisterNewWithCustomerNo'}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    this.props.registerNew(values);
                  }}
                  initialValues={{}}
                  isInitialValid={false}
                  initialValues={{}}
                  validationSchema={registerNewvalidationSchema}
                  enableReinitialize={true}
                  validateOnChange={true}
                  validateOnBlur={true}
                  render={({ submitForm, isValid }) => (
                    <form>
                      <Row className="form-group">
                        <Col>
                          <h5>Register New Account?</h5>
                        </Col>
                      </Row>

                      <Row className="form-group">
                        <Col>
                          <Field
                            name="customer_number"
                            component={TextField}
                            maxLength={10}
                            label="Customer Number*"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        {error && (
                          <Col md={12} className="has-error form-group">
                            <i className="fa fa-fw fa-exclamation-triangle" />
                            {error}
                          </Col>
                        )}

                        <Col md={12}>
                          <div className="pull-right">
                            <Button
                              className="btn btn-primary btn--fw"
                              disabled={!isValid || loading}
                              onClick={submitForm}
                            >
                              <span>
                                {loading ? (
                                  <i className="fa fa-fw fa-circle-notch fa-spin" />
                                ) : (
                                  <>
                                    <i className="fa fa-check fa-fw" />
                                    Register
                                  </>
                                )}
                              </span>
                            </Button>{' '}
                            <Button
                              className="btn btn-default btn--fw"
                              disabled={loading}
                              onClick = {decline}
                            >
                              <span>
                                <i className="fa fa-times fa-fw" />
                                Cancel
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  )}/>
            )}

            {!this.state.showQuestion && this.state.existingAccount && (
              <Formik
                displayName={'verifyRegisterExisting'}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  this.props.verifyRegisterExisting(values);
                }}
                initialValues={{}}
                isInitialValid={false}
                initialValues={{}}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                render={({ submitForm, isValid }) => (
                  <form>
                    <Row className="form-group">
                      <Col>
                        <h5>Verify Account</h5>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <Col>
                        <Field
                          name="customer_number"
                          component={TextField}
                          maxLength={10}
                          label="Customer Number*"
                        />
                      </Col>
                      <Col>
                        <Field
                          name="invoice_number"
                          component={TextField}
                          maxLength={10}
                          label="Invoice Number*"
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      {error && (
                        <Col md={12} className="has-error form-group">
                          <i className="fa fa-fw fa-exclamation-triangle" />
                          {error}
                        </Col>
                      )}

                      <Col md={12}>
                        <div className="pull-right">
                          <Button
                            className="btn btn-primary btn--fw"
                            disabled={!isValid || loading}
                            onClick={submitForm}
                          >
                            <span>
                              {loading ? (
                                <i className="fa fa-fw fa-circle-notch fa-spin" />
                              ) : (
                                <>
                                  <i className="fa fa-check fa-fw" />
                                  Register
                                </>
                              )}
                            </span>
                          </Button>{' '}
                          <Button
                            className="btn btn-default btn--fw"
                            disabled={loading}
                            onClick={invalidInvoiceCustomer ? this.reopenNew : decline}
                          // onClick = {decline}
                          >
                            <span>
                              <i className="fa fa-times fa-fw" />
                              Decline
                            </span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                )}
              />
            )}
          </ErrorBoundary>
        </Modal.Body>
      </Modal>
    );
  }
}

CorevistVerifyModal.propTypes = {
  decline: PropTypes.func.isRequired,
  registerNew: PropTypes.func.isRequired,
  verifyRegisterExisting: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default CorevistVerifyModal;
