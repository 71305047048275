import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { Formik, Field } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PhoneField from 'shared/components/form/fields/phone';
import * as Yup from 'yup';
import TextField from 'shared/components/form/fields/text';

const numberReg = /^\d+$/;
const requiredMessage = 'Required';

const validationSchema = Yup.object().shape({
  fax: Yup.string()
    .min(10, 'Must contain 10 numbers')
    .matches(numberReg, 'Must contain 10 numbers')
    .required(requiredMessage)
});

const RegistrationDetails = (bypassFax = false) => (
  <ul>
    {!bypassFax && (
      <li>
        Please enter your fax number which is required for BV360 Reimbursement Solution and click
        “Register”.{' '}
      </li>
    )}

    <li>Your request for enrollment will be sent to the BV360 Reimbursement Solution team. </li>

    <li>
      Due to PHI concerns, the BV360 Reimbursement Solution team will need to speak to you via phone
      to validate your enrollment.{' '}
    </li>

    <li>
      <strong>
        To complete your verification process, please call BV360 Reimbursement Solution at{' '}
        <a href="tel:1-833-692-8360">1-833-692-8360</a> during office hours 9am – 7pm ET.{' '}
      </strong>
    </li>

    <li>
      Once your account has been activated, you will receive an email from no-reply@cmcopilot.com.{' '}
    </li>

    <li>
      You may then log back in to the MyBV360 Portal and click on the BV360 Reimbursement Solution
      tile to access the system.
    </li>
  </ul>
);

export default function({ accept, bypassFax = false, decline, error, loading }) {
  const submitSimple = evt => {
    evt.preventDefault();
    accept({ bypassFax });
  };

  return bypassFax ? (
    <form onSubmit={submitSimple}>
      <Row>
        <Col>
          <RegistrationDetails bypassFax />
        </Col>
      </Row>

      <Row className="form-group">
        {error && (
          <Col md={12} className="has-error form-group">
            <i className="fa fa-fw fa-exclamation-triangle" />
            {error}
          </Col>
        )}

        <Col md={12}>
          <div className="pull-right">
            <Button
              className="btn btn-primary btn--fw"
              disabled={loading}
              type="submit"
              //   onClick={submitForm}
            >
              <span>
                {loading ? (
                  <i className="fa fa-fw fa-circle-notch fa-spin" />
                ) : (
                  <>
                    <i className="fa fa-check fa-fw" />
                    Register
                  </>
                )}
              </span>
            </Button>{' '}
            <Button className="btn btn-default btn--fw" disabled={loading} onClick={decline}>
              <span>
                <i className="fa fa-times fa-fw" />
                Decline
              </span>
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  ) : (
    <Formik
      displayName={'verifyRegisterExisting'}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        accept(values);
      }}
      isInitialValid={false}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={{ fax: undefined }}
      render={({ submitForm, isValid }) => (
        <form>
          <Row>
            <Col>
              <RegistrationDetails />
            </Col>
          </Row>
          <Row className="form-group">
            <Col md={6}>
              <Field name="fax" component={PhoneField} label="Fax Number*" />
            </Col>
          </Row>
          <Row className="form-group">
            {error && (
              <Col md={12} className="has-error form-group">
                <i className="fa fa-fw fa-exclamation-triangle" />
                {error}
              </Col>
            )}

            <Col md={12}>
              <div className="pull-right">
                <Button
                  className="btn btn-primary btn--fw"
                  disabled={!isValid || loading}
                  onClick={submitForm}
                >
                  <span>
                    {loading ? (
                      <i className="fa fa-fw fa-circle-notch fa-spin" />
                    ) : (
                      <>
                        <i className="fa fa-check fa-fw" />
                        Register
                      </>
                    )}
                  </span>
                </Button>{' '}
                <Button className="btn btn-default btn--fw" disabled={loading} onClick={decline}>
                  <span>
                    <i className="fa fa-times fa-fw" />
                    Decline
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    />
  );
}
