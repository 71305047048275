import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';
import apiClient from 'bv360/utils/api-client';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'shared/components/spinner/spinner';
import Container from 'react-bootstrap/Container';
import SuccessModal from 'bv360/components/request-modals/SuccessModal'
import ConfirmationModal from 'bv360/components/request-modals/ConfirmationModal'

class RequestSample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      samples: [],
      selected_contact: {},
      selected_office: null,
      comments: [],
      confirmationModalOpen: false,
      successModalOpen: false,
      loading: true,
      error: null
    };
  }

  removeDuplicates(myArr, prop) {
    if (myArr === null || myArr === undefined) return [] 
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  async componentDidMount() {
    
    try {
      let sample_list = await apiClient.get(`/mybv360/api/sample-library`);

      if (sample_list.body.error) {
        this.setState({ error: sample_list.body.error });
      } else {
        if (sample_list && sample_list.body && sample_list.body.data) {
          // sample_list = sample_list.body.data
          // filter so only durolane for now       
          sample_list = sample_list.body.data.filter(
            val =>
              val?.product_name?.toUpperCase().includes('DUROLANE')  ||
              val?.product_name?.toUpperCase().includes('GELSYN')
          );
        }

        this.setState({ loading: false, samples: sample_list });
      }
    } catch (e) {      
      this.setState({ loading: false, error: e });
    }
  }

  sendEmail = async (offices = [], contacts = []) => {
    let selectedSamples = this.state.samples.filter(val => val.checked === true);
    selectedSamples.map(val => (val['item_name'] = val['product_name'] + ' ' + val['sample_type']));


    this.setState({ loading: true, error: null });
    try {
      const response = apiClient.post(`/mybv360/api/send-itemrequest-email`, {
        params: {
          office: this.state.selected_office ? this.state.selected_office : offices[0],
          items: selectedSamples,
          comments: this.state.comments.length === 0 ? 'None' : this.state.comments,
          // since the selector is closed turned off for now
          // contact: this.state.selected_contact.email ? this.state.selected_contact.email : contacts[0].email
          contact: contacts[0].email
        }
      });
      if (response.body.error) {
        this.setState({ error: response.body.error });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: e?.body?.error ? e.body.error : 'Error sending email'
      });
    }


  };

  confirmationModalToggle = () => {
    this.setState({
      confirmationModalOpen: !this.state.confirmationModalOpen
    });
  };

  successModalToggle = () => {
    this.setState({
      successModalOpen: !this.state.successModalOpen
    });
  }

  handleCheckChange = (index, event) => {
    let samples = [...this.state.samples]; // immutably set documents to this.state.documents
    let sample = { ...samples[index], checked: event.target.checked, quantity: '1' }; // modify the one which was checked

    samples[index] = sample;    
    this.setState({ samples });
  };

  handleQuantityChange = (index, event) => {
    let samples = [...this.state.samples]; // immutably set documents to this.state.documents
    let sample = { ...samples[index], quantity: event.target.value }; // modify the one which was checked

    samples[index] = sample;    
    this.setState({ samples });
  };

  handleOfficeChange = event => {
    this.setState({ selected_office: event.target.value });
  };

  handleCommentChange = event => {    
    this.setState({ comments: event.target.value });
  };

  handleDeliverToChange = selectedContact => {
    this.setState({ selected_contact: selectedContact });
  };

  render() {
    const isSystemsAdmin = this.props.user?.groups?.is_superuser || false
    const isBV360CustomerService = this.props.user?.groups?.includes('BV360 Customer Service') || false

    if (this.state.loading || this.props.globalStateLoading) {
      return (
        <div style={{ paddingTop: '120px', paddingBottom: '8rem' }} >
          <Spinner  isStatic={true} />
        </div>
      )
    }

    if (this.props.practices.length === 0) {
      if (isSystemsAdmin || isBV360CustomerService) {
        return (
          <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
            <h1> You are not currently associated with any practice. Please first search for and select a practice from 'Superuser Practice Administration'</h1>{' '}
          </Container>
        )
      } else {
        return <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
          <h1> The current user is not associated with any practice.</h1>{' '}
        </Container>
      }
    }

    // grab the samples from local state and filter for the ones which are checked
    const samples = this.state.samples;
    let selectedSamples = samples.filter(val => val.checked === true);

    // find the unique sample types
    const sample_types = samples
      .map(val => val.product_name)
      .filter((item, i, ar) => ar.indexOf(item) === i);

    let offices = [];
    offices = this.props.practices?.reduce((acc, curr) => {
        return acc.concat(curr.offices);
      }, [])
      .filter(val => val != null) // this filters out both null and undefined
      .map(val => val.address1);

    let contacts = [];
    contacts = this.props.practices?.reduce((acc, curr) => {
        return acc.concat(curr.offices);
      }, [])
      .filter(val => val.contacts != null) // this filters out both null and undefined
      .reduce((acc, curr) => {
        return acc.concat(curr.contacts);
      }, [])
      .filter(contact => contact.name !== 'Customer Care');

    if (contacts.length) {
      contacts = this.removeDuplicates(contacts, 'name');
    }

    return (
      <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
        {offices ? (
          <>
            <Form>
              <div style={{ fontSize: '1.5rem' }}>Request Evaluation Sample</div>
              <br /> Evaluation Samples are provided to assess the appropriate use and functionality
              of the product to determine whether and when to use, order, purchase, or recommend the
              product in the future. Provider may not have purchased or used DUROLANE or GELSYN-3 within the
              last 365 days. Provider may not resell, trade, or return the Evaluation Sample for
              credit. Provider must attest that he/she will not bill the patient or any government
              program or commercial payer for the Evaluation Samples. <br /> <br />
              {sample_types.map(type => (
                <div key={type}>
                  <Form.Group controlId={type}>
                    <Form.Label style={{ fontSize: 18 }}>{type}</Form.Label>

                    {samples.map((val, index) =>
                      val.product_name === type ? (
                        <Form.Row key={val.sample_type}>
                          <Col>
                            <Form.Check
                              style={{ fontSize: 14 }}
                              type={'checkbox'}
                              id={val.sample_type}
                              label={val.sample_type}
                              onChange={e => this.handleCheckChange(index, e)}
                            />
                          </Col>
                          <Col>
                            {val.checked && (
                              <Form.Control
                                as="select"
                                style={{ width: '100px' }}
                                onChange={e => this.handleQuantityChange(index, e)}
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </Form.Control>
                            )}
                          </Col>
                        </Form.Row>
                      ) : null
                    )}
                  </Form.Group>
                </div>
              ))}
              <Form.Group controlId="offices">
                <Form.Label style={{ fontSize: 20 }}>Deliver To: </Form.Label>
                <Form.Control
                  as="select"
                  style={{ width: '500px' }}
                  onChange={e => this.handleOfficeChange(e)}
                >
                  {offices ? offices.map((val, index) => <option key={`${val}-${index}`}>{val}</option>) : null}
                </Form.Control>
              </Form.Group>
              {/* <Form.Group controlId="contacts">
              <Form.Label style={{ fontSize: 20 }}>Delivered By: </Form.Label>
              <Form.Control as="select" style={{ width: "500px" }}
                onChange={e => {
                  const selectedContact = contacts.filter(val => val.name == e.target.value)
                  this.handleDeliverToChange(...selectedContact)}}>


                {contacts ?
                  contacts.map((val, index) => (
                    <option key={val.email}>{val.name}</option>
                  ))
                  : null
                }
              </Form.Control>
            </Form.Group> */}
              <Form.Group controlId="sampleComments">
                <Form.Label style={{ fontSize: 20 }}>Comments (optional)</Form.Label>
                <Form.Control as="textarea" rows="4" onChange={e => this.handleCommentChange(e)} />
              </Form.Group>
              <Button
                variant="primary"
                onClick={event => {
                  // this.sendEmail(offices, contacts);
                  this.confirmationModalToggle();
                }}
                style={{ fontSize: 18 }}
                disabled={selectedSamples.length > 0 ? false : true}
              >
                Request
              </Button>
            </Form>

            <SuccessModal
              show={this.state.successModalOpen}
              onHide={this.successModalToggle}
              modalSamples={selectedSamples}
              modalOffice={this.state.selected_office}
              allOffices={offices}
              requestType = {'item'}
            />

            <ConfirmationModal
              show={this.state.confirmationModalOpen}
              onHide={this.confirmationModalToggle}
              successModal = {this.successModalToggle}
              sendEmail = {this.sendEmail}
              offices = {offices}
              contacts = {contacts}
              requestType = {'item'}
            />

          </>
        ) : (
          <h1 style={{ fontSize: 20 }}>
            {' '}
            No offices found for user {this.props.user.firstName} {this.props.user.lastName}
          </h1>
        )}
      </Container>
    );
  }
}

export default connect(
  state => ({
    user: state.user.user,
    practices: state.user.practices,
    globalStateLoading: state.user.loading
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch)
    }
  })
)(RequestSample);
