import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'bv360/redux/actions/user';

import { getPractices } from 'bv360/redux/actions/user';

import Spinner from 'shared/components/spinner/spinner';

import config from 'bv360/config';
import 'react-json-pretty/themes/monikai.css';

import PracticeForm from './Form';
// import './styles.css';
import { practiceSchema, practiceSchemaNewOffice } from './ValidationSchema';
import isEmpty from 'lodash.isempty';

export default function({ exogenRx = false, modal = false, bv360 = false }) {
  const dispatch = useDispatch();
  const { practice_edit_error, loading, error, practices, practice_users, user } = useSelector(
    state => state.user
  );

  useEffect(() => {
    if (!modal) dispatch(getPractices({}, false));
  }, []);

  const [addOffice, setAddOffice] = useState(false);

  if (loading) {
    return <Spinner />;
  }

  const reduxValues = {
    practice: practices.map(val => {
      let formikValue = {};
      val.name_edited ? (formikValue.name = val.name_edited) : (formikValue.name = val.name);
      val.npi_edited ? (formikValue.npi = val.npi_edited) : (formikValue.npi = val.npi);

      val.tax_id_edited
        ? (formikValue.tax_id = val.tax_id_edited)
        : (formikValue.tax_id = val.tax_id);

      formikValue.organization = val.organization
      formikValue.id = val.id;
      formikValue.offices = val.offices;
      formikValue.removed_date = val.removed_date;
      formikValue.alt_npis = val.alt_npis?.join(", ")

      // formikValue.newOffice = {}
      // formikValue.newOffice.main_telephone = undefined
      // formikValue.newOffice.fax = undefined

      if (formikValue.offices) {
        formikValue.offices.map((val, index) => {
          formikValue.offices[index].mdm_id = val.mdm_id;
          val.office_name_edited
            ? (formikValue.offices[index].office_name = val.office_name_edited)
            : (formikValue.offices[index].office_name = val.office_name);
          val.address1_edited
            ? (formikValue.offices[index].address1 = val.address1_edited)
            : (formikValue.offices[index].address1 = val.address1);
          val.address2_edited
            ? (formikValue.offices[index].address2 = val.address2_edited)
            : (formikValue.offices[index].address2 = val.address2);
          val.city_edited
            ? (formikValue.offices[index].city = val.city_edited)
            : (formikValue.offices[index].city = val.city);
          val.state_edited
            ? (formikValue.offices[index].state = val.state_edited)
            : (formikValue.offices[index].state = val.state);

          val.main_telephone_edited
            ? (formikValue.offices[index].main_telephone = val.main_telephone_edited)
            : (formikValue.offices[index].main_telephone = val.main_telephone);
          val.main_telephone_extension_edited
            ? (formikValue.offices[index].main_telephone_extension =
                val.main_telephone_extension_edited)
            : (formikValue.offices[index].main_telephone = val.main_telephone);
          val.fax_edited
            ? (formikValue.offices[index].fax = val.fax_edited)
            : (formikValue.offices[index].fax = val.fax);

          val.zip_edited
            ? (formikValue.offices[index].zip = val.zip_edited)
            : (formikValue.offices[index].zip = val.zip);

          formikValue.offices[index].removed_date = val.removed_date;

          if (
            formikValue.offices[index].main_telephone &&
            formikValue.offices[index].main_telephone.length > 10
          ) {
            formikValue.offices[index].main_telephone = val.main_telephone.slice(2);
          }
          if (formikValue.offices[index].fax && formikValue.offices[index].fax.length > 10) {
            formikValue.offices[index].fax = val.fax.slice(2);
          }

          if (
            formikValue.offices[index].main_telephone_edited &&
            formikValue.offices[index].main_telephone_edited.length > 10
          ) {
            formikValue.offices[index].main_telephone_edited = val.main_telephone_edited.slice(2);
          }
          if (
            formikValue.offices[index].fax_edited &&
            formikValue.offices[index].fax_edited.length > 10
          ) {
            formikValue.offices[index].fax_edited = val.fax_edited.slice(2);
          }
        });
      }

      return formikValue;
    })
  };



  const isSystemsAdmin = (user && user.groups && user.is_superuser) || false;
  const isBV360CustomerService = user?.groups?.includes('BV360 Customer Service') || false;

  let ContainerStyle = {};
  isSystemsAdmin || isBV360CustomerService
    ? (ContainerStyle = { paddingTop: '1rem', paddingBottom: '1rem' })
    : (ContainerStyle = { paddingTop: '120px', paddingBottom: '8rem' });

  if (practices.length === 0 && loading === false) {
    return <div>1</div>;
  } else if (
    isEmpty(practice_users[0]) &&
    !isSystemsAdmin &&
    !isBV360CustomerService &&
    loading === false
  ) {
    return (
      <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
        <h1> The current user is not the administrator of any practice.</h1>{' '}
      </Container>
    );
  }

  return (
    <Container style={ContainerStyle}>
      <Row>
        <Formik
          initialValues={reduxValues}
          enableReinitialize={true}
          isInitialValid={true}
          onSubmit={values => {
            dispatch(updateUser(values));
          }}
          displayName={'PracticeDetails'}
          // validationSchema={validationSchema}
          validationSchema={addOffice ? practiceSchemaNewOffice : practiceSchema}
          // validate={validate}
          validateOnChange={true}
          render={props => {

            return (
              <PracticeForm
                {...props}
                loading={loading}
                error={error}
                practice_edit_error={practice_edit_error}
                currentStoreValues={reduxValues}
                isSystemsAdmin={isSystemsAdmin}
                setAddOffice={setAddOffice}
                addOffice={addOffice}
              />
            );
          }}
        />
      </Row>
    </Container>
  );
}
