import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CopilotRegisterForm from './copilot-register-form';

class RegisterModal extends Component {
  render() {
    const { corevist, bypassFax, accept, decline, error, loading, show } = this.props;

    return (
      <Modal show={show} onHide={decline}>
        <Modal.Header closeButton>
          <Modal.Title>
            Register: {bypassFax}
            {corevist ? 'Injectable Online Orders and Payment' : 'BV360 Reimbursement Solution'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                Do you want to be setup in{' '}
                {corevist ? 'Injectable Online Orders and Payment' : 'BV360 Reimbursement Solution'}
                ?
              </p>
            </Col>
          </Row>
          {corevist ? (
            <>
              <Row>
                <Col>
                  <ul>
                    <li>
                      First we will need your account # and invoice # to assist with registering for
                      the Injectables Orders and Payment system (located on the bottom right corner
                      of your invoice).
                    </li>
                    <li>
                      If you don't have both of these items, please take a moment to locate them or
                      reach out to your sales rep to help locate.{' '}
                    </li>
                    <li>
                      Once the registration request is received, an A/R account specialist will
                      reach out within 24-48 business hours to verbally confirm your request.
                    </li>

                    <li>
                      Once your account has been activated, you will be able to log back in to the
                      MyBV360 Portal and click on the Injectables Orders and Payment tile to access
                      the system.
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row className="form-group">
                {error && (
                  <Col md={12} className="has-error form-group">
                    <i className="fa fa-fw fa-exclamation-triangle" />
                    {error}
                  </Col>
                )}
                <Col md={12}>
                  <div className="pull-right">
                    <Button className="btn btn-primary btn--fw" disabled={loading} onClick={accept}>
                      {loading ? (
                        <i className="fa fa-fw fa-circle-notch fa-spin" />
                      ) : (
                        <>
                          <i className="fa fa-check fa-fw" />
                          Register
                        </>
                      )}
                    </Button>{' '}
                    <Button
                      className="btn btn-default btn--fw"
                      disabled={loading}
                      onClick={decline}
                    >
                      <span>
                        <i className="fa fa-times fa-fw" />
                        Decline
                      </span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <CopilotRegisterForm
              accept={accept}
              bypassFax={bypassFax}
              decline={decline}
              error={error}
              loading={loading}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

RegisterModal.propTypes = {
  decline: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default RegisterModal;
