import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import _set from 'lodash.set';
import _isEqual from 'lodash.isequal';
import { Field } from 'formik';
import * as Yup from 'yup';

import TextField from 'shared/components/form/fields/text';
import RadioGroup from 'shared/components/form/fields/radio-group';

import EmailVerifyField from 'shared/components/form/fields/email-verify';
import { useDispatch, useSelector } from 'react-redux';
import { editPracticeUser } from 'bv360/redux/actions/user';
import { Formik } from 'formik';

import ListGroup from 'react-bootstrap/ListGroup';
import DemoAwareButton from 'bv360/components/demo/button';

const radioOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const roleOptions = [
  {
    label: 'Physician',
    value: 'physician'
  },
  {
    label: 'Medical Assistant',
    value: 'ma'
  },
  {
    label: 'Physician Assistant',
    value: 'pa'
  },
  {
    label: 'Authorization Specialist',
    value: 'as'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const roleOptionsNoPhys = [
  {
    label: 'Medical Assistant',
    value: 'ma'
  },
  {
    label: 'Physician Assistant',
    value: 'pa'
  },
  {
    label: 'Authorization Specialist',
    value: 'as'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export default function editUserModal(props) {
  const { loading } = props;

  const dispatch = useDispatch();

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit User Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            ...props.selectedUser,
            currentUserUsername: props.currentUserUsername,
            role: props.selectedUser?.role?.name
          }}
          onSubmit={values => {
            props.onHide();
            dispatch(editPracticeUser(values));
          }}
          render={renderProps => {
            return <EditPracticeUserForm {...props} {...renderProps} loading={loading} />;
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

const requiredMessage = 'Required';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email'),
  user: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage)
  })
});

class EditPracticeUserForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showEmailField: false
    };
  }

  toggleEmailField = e => {
    this.setState({
      showEmailField: !this.state.showEmailField
    });
  };

  re = /^[0-9]*$/;
  isAllNumbers(val) {
    return this.re.test(val);
  }

  render() {
    const { error, handleSubmit, isAdmin, loading, values, isValid } = this.props;
    const isUser = !this.isAllNumbers(this.props.selectedUser.user.username);

    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="registration-form">
        <Card>
          <Card.Header style={{ fontWeight: '100' }}>
            <Form.Row>
              <Col xs={12} sm={6}>
                <Field
                  name="user.first_name"
                  label="First Name"
                  placeholder={this.props.initialValues.user.first_name}
                  maxLength={64}
                  component={TextField}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="user.last_name"
                  label="Last Name"
                  placeholder={this.props.initialValues.user.last_name}
                  maxLength={64}
                  component={TextField}
                />
              </Col>
            </Form.Row>
          </Card.Header>

          <ListGroup variant="flush">
            <ListGroup.Item>
              <Field
                component={RadioGroup}
                name="is_admin"
                label="Let this user administer other users"
                elems={radioOptions}
                disabled={false}
                labelStyle={{ marginTop: '0.5rem' }}
              />
            </ListGroup.Item>
          </ListGroup>
          {isAdmin && (
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Field
                  component={RadioGroup}
                  name="user.profile.corevist_account"
                  label="Corevist Account Exists"
                  elems={radioOptions}
                  labelStyle={{ marginTop: '0.5rem' }}
                />
              </ListGroup.Item>
            </ListGroup>
          )}
        </Card>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>User's Position in Practice</ListGroup.Item>
            <ListGroup.Item>
              <Field
                component={RadioGroup}
                name="role"
                label="Role"
                inline={false}
                elems={ (this.props.initialValues.role === 'physician') ? roleOptions : roleOptionsNoPhys }
                // disabled={ (values.role == 'physician' || this.props.initialValue === 'physician') ? false : true }
                // disabled={ (this.props.initialValues.role != 'physician') ? false : true }
                // disabled={ (this.props.role.value != 'physician') ? false : true }
                disabled={ (values.role === 'physician') ? true : false }
                labelStyle={{ marginTop: '0.5rem' }}
              />
            </ListGroup.Item>
          </ListGroup>
        </Card>

        {!isUser && (
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Form.Check
                  type="checkbox"
                  label="Create myBV360 User"
                  onClick={e => this.toggleEmailField(e)}
                />
              </ListGroup.Item>
              {this.state.showEmailField && (
                <ListGroup.Item>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Email (For Site Access)"
                      name="email"
                      placeholder="Email"
                      component={EmailVerifyField}
                      maxLength={64}
                      verifyUrl="/mybv360/api/verify-user/contact/"
                      validate={value => {
                        return this.props.status.provider.email;
                      }}
                    />
                  </Col>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        )}

        <Form.Row>
          <Col>
            <Alert variant="danger" show={!!error}>
              <i className="fa fa-fw fa-exclamation-triangle" /> {error}
            </Alert>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={{ span: 2, offset: 10 }}>
            <DemoAwareButton
              demoAlert="This would update a user"
              type="submit"
              disabled={
                loading || _isEqual(this.props.initialValues, this.props.values) || !isValid
              }
            >
              {loading ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Update'}
            </DemoAwareButton>
          </Col>
        </Form.Row>
      </form>
    );
  }
}
