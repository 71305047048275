import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import _set from 'lodash.set';
import { Field } from 'formik';
import RadioGroupField from 'shared/components/form/fields/radio-group';
import TextField from 'shared/components/form/fields/text';
import _isEqual from 'lodash.isequal';

const radioOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const practiceOrGroupRadio = [
  {
    label: 'Practices',
    value: 'practices'
  },
  {
    label: 'Groups',
    value: 'groups'
  }
];

export default class SearchForm extends React.PureComponent {
  columns = [
    {
      Header: 'Practice Name',
      id: 'firstName',
      accessor: 'practice.practice_name',
      minWidth: 900
    }
  ];

  render() {
    const { error, handleSubmit, isValid, loading } = this.props;

    return (
      <form onSubmit={handleSubmit} className="registration-form">
        <Card>
          <Card.Body>
            <Form.Row>
              <Col xs={12} sm={6} style={{paddingRight: '30px'}}>
                <Field
                  name={'practice.search'}
                  maxLength={64}
                  label="Name, Zip, NPI, Email, MDM"
                  component={TextField}
                />
              </Col>
              <Col xs={12} sm={{ span: 3, offset: 0 }}>
                <Field
                  name="practice.practiceOrGroup"
                  label="Search"
                  component={RadioGroupField}
                  elems={practiceOrGroupRadio}
                />
              </Col>
              <Col xs={12} sm={{ span: 3, offset:  0}}>
                <Field
                  name="practice.showWithRemovedDate"
                  label="Show Practices With Removed Date: "
                  component={RadioGroupField}
                  elems={radioOptions}
                />
              </Col>


            </Form.Row>
            <Form.Row>
              <Col>
                <Alert variant="danger" show={!!error}>
                  <i className="fa fa-fw fa-exclamation-triangle" /> {error}
                </Alert>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={{ offset: 0 }}>
                <Button type="submit" disabled={!isValid || loading}>
                  {loading ? (
                    <span>
                      <i className="fa fa-fw fa-circle-notch fa-spin" />
                    </span>
                  ) : (
                    'Search'
                  )}
                </Button>
              </Col>
            </Form.Row>
          </Card.Body>
        </Card>
      </form>
    );
  }
}
