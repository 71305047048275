import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import _set from 'lodash.set';
import { Field } from 'formik';

import TextField from 'shared/components/form/fields/text';

import RadioGroup from 'shared/components/form/fields/radio-group';
import PhoneField from 'shared/components/form/fields/phone';
import EmailVerifyField from 'shared/components/form/fields/email-verify';

import { useDispatch } from 'react-redux';
import { addPracticeUser } from 'bv360/redux/actions/user';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ListGroup from 'react-bootstrap/ListGroup';
import DemoAwareButton from 'bv360/components/demo/button';

const requiredMessage = 'Required';

const alphabetReg = /^[a-zA-Z-',. ]+$/;
const alphabetMessage = 'Invalid character';

const numberReg = /^\d+$/;

const addUserValidation = Yup.object().shape({
  practiceContact: Yup.object().shape({
    firstName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .required(requiredMessage)
      .nullable(),
    lastName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .required(requiredMessage)
      .nullable(),
    email: Yup.string()
      .email('Invalid email')
      .required(requiredMessage)
  }),
  join_copilot: Yup.bool().required(requiredMessage)
});

const addUserValidationWithFax = Yup.object().shape({
  practiceContact: Yup.object().shape({
    firstName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .required(requiredMessage)
      .nullable(),
    lastName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .required(requiredMessage)
      .nullable(),
    email: Yup.string()
      .email('Invalid email')
      .required(requiredMessage)
  }),
  join_copilot: Yup.bool().required(requiredMessage),
  fax: Yup.string().when('join_copilot', {
    is: true,
    then: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .matches(numberReg, 'Must contain 10 numbers')
      .required(requiredMessage),
    else: Yup.string()
  })
});

const radioOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const roleOptions = [
  {
    label: 'Medical Assistant',
    value: 'ma'
  },
  {
    label: 'Physician Assistant',
    value: 'pa'
  },
  {
    label: 'Authorization Specialist',
    value: 'as'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export default function addUserModal(props) {
  const { loading, error, practices, requireFax } = props;
  if (!practices || practices.length === 0) {
    return null;
  }
  const practice = practices[0];
  const { has_copilot_member } = practice;
  const collectFax = requireFax || !has_copilot_member;

  const dispatch = useDispatch();
  const submit = values => dispatch(addPracticeUser(values));

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          // the initial assumption here is that you're only going to be the admin of one practice
          // maybe a physician might be the admin of multiple practices?
          validationSchema={collectFax ? addUserValidationWithFax : addUserValidation}
          initialValues={{ role: '', practice: practices[0], join_copilot: true, is_admin: false }}
          onSubmit={submit}
          render={props => {
            return (
              <AddPracticeUserForm
                {...props}
                collectFax={collectFax}
                loading={loading}
                error={error}
              />
            );
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

function AddPracticeUserForm({
  collectFax,
  error,
  handleSubmit,
  isValid,
  loading,
  setFieldValue,
  setStatus,
  status,
  values
}) {
  useEffect(() => {
    setStatus({ provider: { email: undefined } });
  }, []);

  const { join_copilot } = values;

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className="registration-form">
      <Card>
        <Card.Body>
          <Form.Row>
            <Col xs={12} sm={6}>
              <Field
                name="practiceContact.firstName"
                label="First Name*"
                placeholder="First Name"
                maxLength={64}
                component={TextField}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                name="practiceContact.lastName"
                label="Last Name*"
                placeholder="Last Name"
                maxLength={64}
                component={TextField}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} sm={6}>
              <Field
                name="practiceContact.email"
                label="Email* (Email Will be Username)"
                maxLength={64}
                verifyUrl="/mybv360/api/verify-user/contact/"
                placeholder="Email*"
                component={EmailVerifyField}
                validate={value => {
                  return status?.provider?.email;
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <div style={{ color: 'red' }}>
                Note: New user must log into account within 7 days or account will be deactivated.
              </div>
            </Col>
          </Form.Row>
        </Card.Body>
      </Card>

      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Field
              component={RadioGroup}
              name="is_admin"
              label="Let this user administer other users"
              elems={radioOptions}
              disabled={false}
              labelStyle={{ marginTop: '0.5rem' }}
            />
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item>User's Position in Practice</ListGroup.Item>
          <ListGroup.Item>
            <Field
              component={RadioGroup}
              name="role"
              label="Role"
              inline={false}
              elems={roleOptions}
              disabled={false}
              labelStyle={{ marginTop: '0.5rem' }}
            />
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item>BV360 Reimbursement Solution</ListGroup.Item>
          <ListGroup.Item>
            <Form.Row>
              <Col xs={12} sm={4}>
                <Field
                  component={RadioGroup}
                  onChange={val => {
                    if (!val) {
                      setFieldValue('fax', '');
                    }
                  }}
                  name="join_copilot"
                  label="Opt-in"
                  elems={radioOptions}
                  disabled={false}
                  labelStyle={{ marginTop: '0.5rem' }}
                />
              </Col>
              {join_copilot && collectFax && (
                <Col xs={12} sm={4}>
                  <Field name="fax" component={PhoneField} label="Fax Number*" />
                </Col>
              )}
            </Form.Row>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Form.Row>
        <Col>
          <Alert variant="danger" show={!!error}>
            <i className="fa fa-fw fa-exclamation-triangle" /> {error}
          </Alert>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={{ span: 2, offset: 10 }}>
          <DemoAwareButton
            demoAlert="This would add a user"
            type="submit"
            disabled={loading || !isValid}
          >
            {loading ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Submit'}
          </DemoAwareButton>
        </Col>
      </Form.Row>
    </form>
  );
}
