import React from 'react';
import { Field } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SelectField from 'shared/components/form/fields/select';
import TextAreaField from 'shared/components/form/fields/textarea';

class ContactUsForm extends React.PureComponent {
  // constructor(props) {
  //   super(props);
  // }

  setResponderOptions() {
    const responderOptions = this.props.contacts
      ? this.props.contacts.map(val => ({
          label: val.name,
          value: { email: val.email, phone: val.phone, name: val.name }
        }))
      : null;

    if (this.props.values?.helpmenu?.type === 'Benefits Investigations') {
      return responderOptions.filter(val => val.label === 'BV360 Reimbursement Solution');
    } else {
      return responderOptions.filter(val => val.label !== 'BV360 Reimbursement Solution');
    }
  }

  componentDidUpdate() {
    const responderOptions = this.props.contacts
      ? this.props.contacts.map(val => ({
          label: val.name,
          value: { email: val.email, phone: val.phone, name: val.name }
        }))
      : null;
    const benefitInvestIndex = responderOptions
      ?.map(val => val.label)
      ?.indexOf('BV360 Reimbursement Solution');
    if (
      this.props.values?.helpmenu?.type === 'Benefits Investigations' &&
      responderOptions &&
      benefitInvestIndex &&
      !this.props.values?.helpmenu?.responder
    ) {
      this.props.setFieldValue('helpmenu.responder', responderOptions[benefitInvestIndex]);
    }
  }

  render() {
    const { values, isValid, error, contacts, loading } = this.props;

    // const responderOptions = contacts ? contacts.map( val => ( {label: val.name, value: {email: val.email, phone: val.phone, name: val.name} }) ) : null
    // const benefitInvestIndex = responderOptions?.map(val => val.label)?.indexOf('BV360 Support Team')

    // if(this.props.values?.helpmenu?.type?.value === 'Benefits Investigations' && responderOptions && benefitInvestIndex ) {
    //             this.props.setFieldValue('helpmenu.responder',responderOptions[benefitInvestIndex]) }

    return (
      <form>
        <Row className="form-group">
          <Col xs={12} sm={6}>
            <Field
              name="helpmenu.type"
              label="What can we help with?"
              // maxLength={64}
              simpleValue
              component={SelectField}
              options={[
                { label: 'Account Help', value: 'Account Help' },
                { label: 'Order Help', value: 'Order Help' },
                { label: 'Product Help', value: 'Product Help' },
                { label: 'System Help', value: 'System Help' },
                { label: 'Benefits Investigations', value: 'Benefits Investigations' }
              ]}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Field
              name="helpmenu.product"
              label="What product is this for"
              // maxLength={64}
              simpleValue
              component={SelectField}
              options={[
                { label: 'EXOGEN', value: 'EXOGEN' },
                { label: 'DUROLANE', value: 'DUROLANE' },
                { label: 'GELSYN-3', value: 'GELSYN-3' },
                { label: 'SUPARTZ-FX', value: 'SUPARTZ-FX' },
                { label: 'Not Applicable', value: 'Not Applicable' }
              ]}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs={24} sm={12}>
            <Field
              name="helpmenu.responder"
              label="Send message to:"
              component={SelectField}
              options={this.setResponderOptions()}
              // options = {this.responderOptions}
              // defaultValue = {this.props.values?.helpmenu?.type?.value === 'Benefits Investigations' && responderOptions && benefitInvestIndex ?
              //   responderOptions[benefitInvestIndex] : responderOptions[0] }
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs={24} sm={12}>
            <Field
              name="helpmenu.text"
              // label="What can we help with?"
              maxLength={2048}
              component={TextAreaField}
              placeholder="Enter additional details here. Please avoid submitting PHI."
            />
          </Col>
        </Row>

        {this.props.values?.helpmenu?.type === 'Benefits Investigations' && (
          <Row className="form-group">
            <Col xs={24} sm={12}>
              <Field
                name="helpmenu.casenum"
                // label="What can we help with?"
                maxLength={30}
                component={TextAreaField}
                placeholder="Enter case number if known"
                rows="2"
              />
            </Col>
          </Row>
        )}

        <Row className="form-group">
          <Col md={4}>
            {error && (
              <div className="col-md-12 has-error">
                <p className="pull-left">
                  <i className="fa fa-exclamation-triangle fa-fw has-error" />
                  {error}
                </p>
              </div>
            )}
          </Col>
          <Col md={8}>
            <div className="pull-right">
              <Button
                disabled={loading}
                onClick={this.props.closeModal}
                variant="secondary"
                className="btn--fw"
              >
                Cancel
              </Button>{' '}
              <Button
                disabled={loading || !isValid}
                className="btn btn-primary btn--fw"
                onClick={this.props.submitForm}
              >
                {loading ? <i className="fa fa-fw fa-lg fa-circle-notch fa-spin" /> : 'Send'}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

export default ContactUsForm;
