import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';
import { upperCaseFirstLetter } from 'shared/utils/string';
import { logout } from 'bv360/redux/actions/user';
import ContactUsModal from './modals/contact-us/';
import ThirdPartyModals from 'bv360/components/dashboard/third-party-modals';
import config from 'bv360/config';

import {
  corevistAccountStatus,
  toggleCorevistModal,
  toggleCopilotModal,
  toggleWarningModal
} from 'bv360/components/dashboard/reducer';

const LeftLogo = require('static/images/myBV360logo_white.png');
const RightLogo = require('static/images/bioventus-right.png');

export default function({
  pathname = null,
  demoable = false,
  loadingDemo = false,
  isDemo = false,
  externalPage = false,
  isSystemsAdmin = false,
  isBv360Admin = false,
  isBV360CustomerService = false,
  startDemo,
  stopDemo
}) {
  const user = useSelector(state => state.user && state.user.user);
  const corevistIsDown = useSelector(state => state.dashboard?.systemConfig?.corevistIsDown);

  const dispatch = useDispatch();

  const username = user
    ? `${upperCaseFirstLetter(user.firstName)} ${upperCaseFirstLetter(user.lastName)}`
    : null;

  const [contactUsShow, setContactUsShow] = useState(false);
  const handleContactUsClose = () => setContactUsShow(false);
  const handleContactUsShow = () => setContactUsShow(true);

  const [collapsed, setCollapsed] = useState(false);
  const collapseOnClick = () => setCollapsed(true);
  const classOne = collapsed ? 'collapsed navbar-collapse' : 'collapse navbar-collapse';

  const handleCorevistLink = () => {
    if (!user.is_superuser && user?.is_rep && !user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (user?.corevist_user) {
      if (user?.is_demo) {
        alert(
          'Demo Mode: Customers will be able to access the Injectables Orders and Payment system.'
        );
      } else {
        window.open(config.corevistOkaRedirect, '_blank');
      }
    } else if (user?.corevist_in_progress) {
      dispatch(corevistAccountStatus());
    } else {
      dispatch(toggleCorevistModal(true));
    }
  };

  const handleCopilotLink = () => {
    if (!user.is_superuser && user?.is_rep && !user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (user?.copilot_user) {
      if (!user?.copilot_warning_accepted && user?.copilot_quick_enroll) {
        dispatch(toggleWarningModal());
      } else {
        const url = user?.is_demo ? config.copilotDemoUrl : config.copilotOkaRedirect;
        window.open(url, '_blank');
      }
    } else {
      dispatch(toggleCopilotModal(true, user.practice_has_copilot_user));
    }
  };

  useEffect(() => {
    // optional chaining is an attempt to not break ie11
    const nodeList = document?.querySelectorAll('a.dropdown-toggle');
    nodeList?.forEach?.(elem => {
      elem.addEventListener('click', e => {
        e?.stopPropagation();
        // Let's hide the open siblings
        const subMenus = document?.querySelectorAll('ul.dropdown-menu .show');
        const subMenu = elem?.nextElementSibling;

        // hiding siblings
        subMenus.forEach(menu => {
          if (menu !== subMenu) menu.classList.remove('show');
        });

        if (subMenu?.classList?.contains('show')) {
          subMenu.classList.remove('show');
          const menusToClose = subMenu.querySelectorAll('ul.dropdown-menu');
          menusToClose.forEach(menuToClose => {
            menuToClose?.classList?.remove?.('show');
          });
        } else {
          subMenu?.classList?.add('show');
        }
      });
    });
  }, []);

  useEffect(() => {
    setCollapsed(false);
    const elems = document?.querySelectorAll?.('#TopHead .show');

    for (let i = 0; i < elems.length; i++) {
      elems[i]?.classList?.remove('show');
    }
  }, [pathname]);

  return (
    <header
      onMouseOver={() => setCollapsed(false)}
      id="TopHead"
      style={{ backgroundImage: 'url(/static/images/dot.png)' }}
    >
      <div className="clear" />

      <div className="Logo">
        <Link to={URLS.BASE}>
          <img src={LeftLogo} alt="myBV360" className="myBV360-Header-Logo" />
        </Link>
      </div>
      <div className="LogoRight">
        <a href="https://www.bioventusglobal.com/" target="_blank">
          <img src={RightLogo} alt="Bioventus Global" />
        </a>
      </div>
      {!externalPage && (
        <div id="TopNav">
          <nav className="navbar navbar-expand-lg mainmenu">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ backgroundColor: 'transparent' }}
            >
              <span className="fa fa-bars" />
            </button>

            <div className={`${classOne}`} id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="linedown dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    myBV360<span></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li onClick={() => setCollapsed(true)}>
                      <Link to={URLS.ACCOUNT_PROFILE} style={{ whiteSpace: 'nowrap' }}>
                        Account Profile
                      </Link>
                    </li>

                    {isBv360Admin === true && (
                      <>
                        <li onClick={() => setCollapsed(true)}>
                          <Link to={URLS.PRACTICE_USER_MANAGEMENT} style={{ whiteSpace: 'nowrap' }}>
                            Practice Users
                          </Link>
                        </li>

                        <li onClick={() => setCollapsed(true)}>
                          <Link
                            to={URLS.PRACTICE_DETAIL_MANAGEMENT}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Practice Details
                          </Link>
                        </li>
                      </>
                    )}
                    {(isSystemsAdmin || isBV360CustomerService) && (
                      <li onClick={() => setCollapsed(true)}>
                        <Link to={URLS.PRACTICE_ADMINISTRATION} style={{ whiteSpace: 'nowrap' }}>
                          Superuser Practice Administration
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className={'linedown dropdown '}>
                  <a
                    href="#"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services<span></span>
                  </a>

                  {/* <ul className={"dropdown-menu " + classTwo}> */}
                  <ul className="dropdown-menu">
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        EXOGEN
                      </a>

                      <ul className="dropdown-menu exogen-services">
                        <li>
                          {config.features.exogenRx && (
                            <a href="/exogen-rx/app" target="_blank">
                              EXOGEN Rx
                            </a>
                          )}
                          {!config.features.exogenRx && <a href="#">EXOGEN Rx (Coming Soon)</a>}
                        </li>
                        {/* <li>
                          <Link to={URLS.REQUEST_SAMPLE} style={{ whiteSpace: 'nowrap' }}>
                            Request Sample
                          </Link>
                        </li> */}
                        <li onClick={collapseOnClick}>
                          <Link
                            to={'/mybv360/app/document-library/exogen'}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Product Literature
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DUROLANE
                      </a>
                      <ul className="dropdown-menu durolane-services">
                        <li>
                          {corevistIsDown ? (
                            <Link to={URLS.COREVIST_DOWN} style={{ whiteSpace: 'nowrap' }}>
                              Ordering/Invoice Payment
                            </Link>
                          ) : (
                            <a onClick={handleCorevistLink}>Ordering/Invoice Payment</a>
                          )}
                        </li>
                        <li>
                          <a onClick={handleCopilotLink}>BV360 Reimbursement Solution</a>
                        </li>
                        <li>
                          <a
                            href="https://www.oakneepainrelief.com/supartz_fx/hcp_resources/"
                            target="_blank"
                          >
                            Patient Direct Purchase Program
                          </a>
                        </li>
                        <li onClick={collapseOnClick}>
                          <Link to={URLS.REQUEST_SAMPLE} style={{ whiteSpace: 'nowrap' }}>
                            Request Evaluation Sample
                          </Link>
                        </li>
                        <li onClick={collapseOnClick}>
                          <Link
                            to={'/mybv360/app/document-library/durolane'}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Product Literature
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        GELSYN-3
                      </a>
                      <ul className="dropdown-menu gelsyn-services">
                        <li>
                          {corevistIsDown ? (
                            <Link to={URLS.COREVIST_DOWN} style={{ whiteSpace: 'nowrap' }}>
                              Ordering/Invoice Payment
                            </Link>
                          ) : (
                            <a onClick={handleCorevistLink}>Ordering/Invoice Payment</a>
                          )}
                        </li>
                        <li>
                          <a onClick={handleCopilotLink}>BV360 Reimbursement Solution</a>
                        </li>
                        <li>
                          <a
                            href="https://www.oakneepainrelief.com/supartz_fx/hcp_resources/"
                            target="_blank"
                          >
                            Patient Direct Purchase Program
                          </a>
                        </li>
                        <li>
                          <Link to={URLS.REQUEST_SAMPLE} style={{ whiteSpace: 'nowrap' }}>
                            Request Evaluation Sample
                          </Link>
                        </li>
                        <li onClick={collapseOnClick}>
                          <Link
                            to={'/mybv360/app/document-library/gelsyn'}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Product Literature
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        SUPARTZ FX
                      </a>
                      <ul className="dropdown-menu supartz-services">
                        <li>
                          {corevistIsDown ? (
                            <Link to={URLS.COREVIST_DOWN} style={{ whiteSpace: 'nowrap' }}>
                              Ordering/Invoice Payment
                            </Link>
                          ) : (
                            <a onClick={handleCorevistLink}>Ordering/Invoice Payment</a>
                          )}
                        </li>
                        <li>
                          <a onClick={handleCopilotLink}>BV360 Reimbursement Solution</a>
                        </li>
                        <li>
                          <a
                            href="https://www.oakneepainrelief.com/supartz_fx/hcp_resources/"
                            target="_blank"
                          >
                            Patient Direct Purchase Program
                          </a>
                        </li>
                        {/* <li>
                          <Link to={URLS.REQUEST_SAMPLE} style={{ whiteSpace: 'nowrap' }}>
                            Request Evaluation Sample
                          </Link>
                        </li> */}
                        <li onClick={collapseOnClick}>
                          <Link
                            to={'/mybv360/app/document-library/supartz'}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Product Literature
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li className="linedown dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Product Information<span></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="https://www.exogen.com/us/physician/" target="_blank">
                        EXOGEN
                      </a>
                    </li>

                    <li>
                      <a href="https://www.durolane.com" target="_blank">
                        DUROLANE
                      </a>
                    </li>

                    <li>
                      <a href="https://www.gelsyn3.com" target="_blank">
                        GELSYN-3
                      </a>
                    </li>

                    <li>
                      <a href="https://www.supartzfx.com" target="_blank">
                        SUPARTZ FX
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="https://www.bioventusacademy.com/"
                    target="_blank"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Bioventus Academy<span></span>
                  </a>
                </li>

                {/* <li>
                  <a href="#">
                    <div className="Notification"></div>Alerts<span></span>
                  </a>
                </li> */}

                <li className="dropdown">
                  <a
                    href="#"
                    // className="dropdown-toggle"
                    // role="button"
                    // data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={handleContactUsShow}
                  >
                    Contact Us<span></span>
                  </a>
                </li>
                {/* </ul> */}
                {/* <ul className="navbar-nav float-lg-right" id="TopNavRight"> */}
                <li className="linedown dropdown" id="TopNavRight">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {username} <i className="fa fa-angle-down sign-out-icon" />
                    <span></span>
                  </a>
                  <ul className="dropdown-menu">
                    {demoable && (
                      <li onClick={isDemo ? stopDemo : startDemo}>
                        <a href="#" onClick={evt => evt.preventDefault()}>
                          {loadingDemo ? (
                            <span>
                              <i className="fa fa-fw fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <span>
                              <i className="fa fa-user-circle" />
                            </span>
                          )}{' '}
                          {isDemo ? 'Stop' : 'Start'} Demo
                        </a>
                      </li>
                    )}

                    <li onClick={() => dispatch(logout())}>
                      <a href="#" onClick={evt => evt.preventDefault()}>
                        <i className="fa fa-sign-out-alt" /> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
          <ContactUsModal show={contactUsShow} onHide={handleContactUsClose} loading={false} />
        </div>
      )}

      <div className="clear" />
      <ThirdPartyModals />
    </header>
  );
}

/* <li>
  <Link to={URLS.ACCOUNT_INFO} style={{ whiteSpace: 'nowrap' }}>
    Account Info
  </Link>
</li> */
